/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const availableLanguages = ["de", "en"];

exports.onInitialClientRender = () => {
    if (!localStorage.getItem("preferredLanguage")) {
        const locale = window.navigator.language.split("-");
        localStorage.setItem("preferredLanguage", locale);
        const currentLang = window.location.pathname.split("/").filter(i => !!i);
        if (currentLang !== locale) {
            if (availableLanguages.includes(locale)) {
                window.location.pathname = `${locale}${window.location.pathname}`;
            }
            else {
                window.location.pathname = `en${window.location.pathname}`;
            }
        }
    }
}
